import React from "react"

import "./albumInfo.scss"

const AlbumInfo = ({ album }) => {
  return (
    <div className="album-info">
      <h1 className="title has-text-centered">{album.name}</h1>
      {album.description && <h1 className="subtitle has-text-centered">{album.description}</h1>}
      <h1 className="subtitle has-text-centered is-uppercase">{album.artist}</h1>
    </div>
  )
}

export default AlbumInfo
