import React from "react"
import PubSub from "pubsub-js"

import { getQueryVariable } from "../util"
import { CODE_CHANGED, RESPONSE_ALBUM_LOADED } from "../events"

import CodeInputs from "./codeInputs"
import Album from "./album"
import CustomCodeInput from "./customCodeInput"

import "./player.scss"

class CodePlayer extends React.Component {
  constructor(props) {
    super(props)

    const isCustomCode = props.isCustomCode
    this.containerRef = React.createRef()
    this.audioPlayerRef = React.createRef()
    this.customCodeInputRef = React.createRef()

    this.height = 0
    this.expectedCodeLength = 8
    this.state = {
      album: undefined,
      albumId: undefined,
      queryCode: getQueryVariable("code") || undefined,
      code: "",
      isCustomCode,
      playingTrackIndex: 0,
    }
  }

  componentDidMount() {
    PubSub.subscribe(RESPONSE_ALBUM_LOADED, this.handleAlbumLoadedEvent)
    PubSub.subscribe(CODE_CHANGED, this.handleCodeChangedEvent)
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.handleAlbumLoadedEvent)
    PubSub.unsubscribe(this.handleCodeChangedEvent)
  }

  handleAlbumLoadedEvent = (_, album) => this.setState({ ...this.state, album })
  handleCodeChangedEvent = (_, code) => this.setState({ ...this.state, code })

  render() {
    return (
      <div className="CodePlayer" ref={this.containerRef}>
        {/* NORMAL 8 CHARACTER CODES */}
        {!this.state.isLoading && !this.state.album && !this.state.isCustomCode && (
          <CodeInputs expectedCodeLength={this.expectedCodeLength} />
        )}

        {/* CUSTOM CODES */}
        {!this.state.isLoading && !this.state.album && this.state.isCustomCode && (
          <CustomCodeInput code={this.state.queryCode} />
        )}

        {/* HAVE ALBUM FROM CODE */}
        {this.state.album && <Album album={this.state.album} code={this.state.code} />}
      </div>
    )
  }
}

export default CodePlayer
