import axios from "axios"

import firebase from "firebase/app"
import posthog from "posthog-js"
import "firebase/analytics"

import Analytics from "analytics"
import googleAnalytics from "@analytics/google-analytics"

export const getAnaltyics = () => {
  let analytics = null
  let gaTrackingId = getQueryVariable("gaTrackingId") || getQueryVariable("ga-tracking-id")

  if (gaTrackingId) {
    analytics = Analytics({
      app: "the-vigil-project",
      plugins: [
        googleAnalytics({
          trackingId: gaTrackingId,
        }),
      ],
    })
  }
  return analytics
}

export const getQueryVariable = (variable) => {
  var query = window.location.search.substring(1)
  var vars = query.split("&")
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=")
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1])
    }
  }
  return undefined
}

export const getParentUrl = () => {
  var isInIframe = window.parent !== window,
    parentUrl = null
  if (isInIframe) {
    parentUrl = document.referrer
  }
  return parentUrl
}

export const postHeightChangeMessage = (currentHeight) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const newHeight = document.getElementById("root").offsetHeight
      if (currentHeight !== newHeight) {
        window.parent.postMessage(
          {
            frameHeight: newHeight,
          },
          "*"
        )
      }
      resolve(newHeight)
    }, 150)
  })
}

export const log = (name, data) => {
  try {
    firebase.analytics().logEvent(name, data)
  } catch {}

  const analytics = getAnaltyics()
  if (analytics) {
    try {
      analytics.track(name, data)
    } catch {}
  }

  try {
    posthog.capture(name, data)
  } catch {}
}

// from https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)#stackoverflow-archive-begin
export const shadeColor = (color, percent) => {
  var R = parseInt(color.substring(1, 3), 16)
  var G = parseInt(color.substring(3, 5), 16)
  var B = parseInt(color.substring(5, 7), 16)

  R = parseInt((R * (100 + percent)) / 100)
  G = parseInt((G * (100 + percent)) / 100)
  B = parseInt((B * (100 + percent)) / 100)

  R = R < 255 ? R : 255
  G = G < 255 ? G : 255
  B = B < 255 ? B : 255

  var RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16)
  var GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16)
  var BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16)

  return "#" + RR + GG + BB
}

export const fancyTimeFormat = (duration) => {
  // Hours, minutes and seconds
  var hrs = ~~(duration / 3600)
  var mins = ~~((duration % 3600) / 60)
  var secs = ~~duration % 60

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = ""

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "")
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "")
  ret += "" + secs
  return ret
}

export const getAbsoluteHeight = (el) => {
  // Get the DOM Node if you pass in a string
  el = typeof el === "string" ? document.querySelector(el) : el

  var styles = window.getComputedStyle(el)
  var surrounding =
    parseFloat(styles.marginTop) +
    parseFloat(styles.marginBottom) +
    parseFloat(styles.paddingTop) +
    parseFloat(styles.paddingBottom)

  return Math.ceil(el.offsetHeight + surrounding)
}

export const fetchCode = async (code) => {
  const url = "https://us-central1-the-vigil-project.cloudfunctions.net/getAlbumInfoFromCode"
  const response = await axios.get(url, { params: { code } })
  log("fetch_code", { code })
  return response
}
