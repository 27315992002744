import React from "react"

import AlbumInfo from "./albumInfo"
import TrackList from "./trackList"
import AudioPlayer from "./audioPlayer"
import DownloadButton from "./downloadButton"
import RedeemButton from "./redeemButton"

import { postHeightChangeMessage } from "../util"

const Album = ({ album, albumId, code }) => {
  let height = 0
  const [playingTrackIndex, setPlayingTrackIndex] = React.useState(0)

  return (
    <div className="container">
      <div className="columns">
        <div className="column is-6 is-offset-3">
          <div className="album">
            <div className="card">
              <AudioPlayer
                isPlaying={false}
                album={album}
                albumId={albumId}
                onPlayIndexChange={async (newIndex) => {
                  setPlayingTrackIndex(newIndex)
                  height = await postHeightChangeMessage(height)
                }}
              />
              <div className="card-content">
                <AlbumInfo album={album}></AlbumInfo>
                <TrackList tracks={album.tracks} playingTrackIndex={playingTrackIndex}></TrackList>
              </div>
              <footer className="card-footer">
                {album.showAttachmentButton !== false && album.featured.file.url && (
                  <button
                    className="is-large is-block button is-light has-accent-light"
                    onClick={() => window.open(album.featured.file.url, "_blank")}>
                    {album.attachmentButtonText || "Companion Booklet Preview"}
                  </button>
                )}

                {album.showRedeemButton !== false && !code && (
                  <DownloadButton text={album.redeemButtonText} albumId={albumId} />
                )}
                {album.showRedeemButton !== false && code && <RedeemButton code={code} />}
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Album
