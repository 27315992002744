import "./newBubble.scss"
const NewBubble = () => {
  return (
    <div class="new-bubble">
      <span class="new-bubble-outer-dot">
        <span class="new-bubble-inner-dot"></span>
      </span>
    </div>
  )
}

export default NewBubble
