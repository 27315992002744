import React from "react"
import ReactJkMusicPlayer from "react-jinke-music-player"
import PubSub from "pubsub-js"
import { REQUEST_PLAY_TRACK } from "../events"

import { fancyTimeFormat, getAbsoluteHeight, getQueryVariable, log } from "../util"
import "./audioPlayer.scss"

export default class AudioPlayer extends React.Component {
  constructor(props) {
    super(props)

    this.audioPlayerRef = React.createRef()
    this.trackTitleRef = React.createRef()

    this.state = {
      allowShuffle:
        getQueryVariable("allowShuffle") === "true" || getQueryVariable("allow-shuffle") === "true" || false,
      shuffle: getQueryVariable("shuffle") === "true",
      hideAlbumArt:
        getQueryVariable("hideAlbumArt") === "true" || getQueryVariable("hide-album-art") === "true" || false,
      album: props.album,
      isPlaying: props.isPlaying !== undefined ? props.isPlaying : true,
      isPaused: props.isPlaying !== undefined ? !props.isPlaying : false,
      playIndex: 0,
      title: "",
      totalTime: 0,
      elapsedTime: 0,
      hasRegisteredScroller: false,
      hasLoggedTrackStarted: false,
      hasLoggedTrackListened30s: false,
      hasLoggedTrackCompleted: false,
    }

    this.onPlayIndexChange = props.onPlayIndexChange
    this.audioInstance = null
    this.audioLists = Object.values(this.state.album.tracks)
      .sort((a, b) => a.order - b.order)
      .map((track, index) => ({
        name: track.friendlyName,
        musicSrc: track.url,
        singer: this.state.album.artist,
        cover: this.state.album.albumArtUrl[0],
        order: track.order,
      }))
  }

  componentDidMount() {
    if (this.onPlayIndexChange === "function") {
      setTimeout(() => {
        this.onPlayIndexChange(this.state.playIndex)
      }, 150)
    }

    if (document.querySelector(".App").classList.contains("fill-parent")) {
      document.addEventListener("resize", this.fillContainer)
      this.fillContainer()
    }

    PubSub.subscribe(REQUEST_PLAY_TRACK, this.handleRequestPlayTrackEvent)
  }

  componentWillUnmount() {
    document.removeEventListener("resize", this.fillContainer)
    PubSub.unsubscribe(this.handleRequestPlayTrackEvent)
  }

  handleRequestPlayTrackEvent = (name, track) => {
    const index = this.audioLists.findIndex((search) => search.name === track.friendlyName)
    this.audioPlayerRef.current.playByIndex(index)
  }

  /**
   * Called when app has `fill-parent` set to true. Expands the player to fill the height of the screen.
   * @returns
   */
  fillContainer = () => {
    const appEl = document.querySelector(".App")

    if (!appEl) return

    const desiredHeight = getAbsoluteHeight(document.body)
    const audioPlayerHeight = getAbsoluteHeight(".AudioPlayer")
    const albumInfoHeight = getAbsoluteHeight(".album-info")
    const styles = window.getComputedStyle(appEl)
    var surrounding =
      parseFloat(styles.marginTop) +
      parseFloat(styles.marginBottom) +
      parseFloat(styles.paddingTop) +
      parseFloat(styles.paddingBottom)

    const padding = 30
    const menuListHeight = desiredHeight - audioPlayerHeight - albumInfoHeight - padding - surrounding

    document.querySelector(".menu-list").style.height = `${menuListHeight}px`
    document.querySelector(".menu-list").style.maxHeight = `${menuListHeight}px`
  }

  playTrack = (track) => {
    const playIndex = this.audioLists.findIndex((item) => item.musicSrc === track.url)
    this.setState({ ...this.state, playIndex })
    this.audioInstance.play()
    return playIndex
  }

  render() {
    return (
      <div className={`AudioPlayer ${this.state.isPlaying || this.state.totalTime !== 0 ? "is-playing" : ""}`}>
        <div className="card-image artwork">
          {!this.state.hideAlbumArt && (
            <div className="album-art" style={{ backgroundImage: `url(${this.state.album.albumArtUrl[0]})` }}></div>
          )}
          <div className="overlay">
            <div className="controls">
              <div className="fluid-container">
                {this.state.title && (
                  <div className="track-meta">
                    <input
                      readOnly={true}
                      disabled={true}
                      ref={this.trackTitleRef}
                      className="track-title"
                      value={this.state.title}
                    />
                    <p className="track-progress">
                      <span className="elapsed">{fancyTimeFormat(this.state.elapsedTime)}</span>
                      {" / "}
                      <span className="total">{fancyTimeFormat(this.state.totalTime)}</span>
                    </p>
                  </div>
                )}
                <div className="columns">
                  <div className={`column ${this.state.allowShuffle ? "is-3" : "is-4"}`}>
                    <button id="audio-player-back" className="icon has-text-centered" name="back">
                      <i className="fas fa-step-backward" onClick={() => this.audioInstance.playPrev()}></i>
                    </button>
                  </div>
                  <div className={`column ${this.state.allowShuffle ? "is-3" : "is-4"}`}>
                    {this.state.isPaused && (
                      <button id="audio-player-play" className="icon has-text-centered" name="play">
                        <i className="fas fa-play" onClick={() => this.audioInstance.play()}></i>
                      </button>
                    )}
                    {this.state.isPlaying && this.state.totalTime === 0 && (
                      <span className="icon has-text-centered">
                        <i className="fas fa-circle-notch" />
                      </span>
                    )}
                    {this.state.isPlaying && this.state.totalTime !== 0 && (
                      <button id="audio-player-pause" className="icon has-text-centered" name="pause">
                        <i className="fas fa-pause" onClick={() => this.audioInstance.pause()}></i>
                      </button>
                    )}
                  </div>
                  {this.state.allowShuffle && (
                    <div className={`column ${this.state.allowShuffle ? "is-3" : "is-4"}`}>
                      <span className="icon has-text-centered">
                        <i
                          className="fas fa-random"
                          style={{ opacity: this.state.shuffle ? 1 : 0.2 }}
                          onClick={() => {
                            this.setState({ ...this.state, shuffle: !this.state.shuffle })
                          }}></i>
                      </span>
                    </div>
                  )}
                  <div className={`column ${this.state.allowShuffle ? "is-3" : "is-4"}`}>
                    <button id="audio-player-next" className="icon has-text-centered" name="next">
                      <i className="fas fa-step-forward" onClick={() => this.audioInstance.playNext()}></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="background"></div>
          </div>
        </div>
        <ReactJkMusicPlayer
          ref={this.audioPlayerRef}
          className="music-player"
          defaultPlayMode={"order"}
          playMode={this.state.shuffle ? "shufflePlay" : "order"}
          autoPlay={!this.state.isPaused}
          getAudioInstance={(instance) => (this.audioInstance = instance)}
          showMediaSession={true}
          bounds={"parent"}
          defaultPlayIndex={0}
          playIndex={this.state.playIndex}
          mode="mini"
          responsive={false}
          preload={true}
          toggleMode={false}
          audioLists={this.audioLists}
          onPlayIndexChange={(newIndex) => {
            this.setState({
              ...this.state,
              playIndex: newIndex,
              hasLoggedTrackStarted: false,
              hasLoggedTrackListened30s: false,
              hasLoggedTrackCompleted: false,
            })
            return typeof this.onPlayIndexChange === "function" ? this.onPlayIndexChange(newIndex) : false
          }}
          onAudioError={(errMsg, currentPlayId, audioLists, audioInfo) => {
            const data = { errMsg, currentPlayId, audioInfo, audioLists }
            console.error(data)
            log("audio_error", data)
          }}
          onAudioPlay={() => this.setState({ ...this.state, isPaused: false, isPlaying: true })}
          onAudioPause={() => this.setState({ ...this.state, isPaused: true, isPlaying: false })}
          onAudioProgress={(albumInfo) => {
            const shortenedTitle = this.audioInstance.title
              .replace(/[^\w]/g, "_")
              .replace(/_{2,}/g, "_")
              .substr(0, 15)
              .toLowerCase()
              .replace(/_$/, "")

            const albumId = this.state.album.id ? this.state.album.id.substr(0, 4) : ""

            const remainingTime = Math.floor(albumInfo.duration - this.audioInstance.currentTime)

            if (Math.floor(this.audioInstance.currentTime) === 1 && !this.state.hasLoggedTrackStarted) {
              this.setState({ ...this.state, hasLoggedTrackStarted: true })
              log(`started_${albumId}_${shortenedTitle}`, {
                albumId: this.state.album.id,
                title: this.audioInstance.title,
              })
            }

            // Log that the song has completed if it has reached < 10s in remaining playback time.
            if (remainingTime === 10 && !this.state.hasLoggedTrackCompleted) {
              this.setState({ ...this.state, hasLoggedTrackCompleted: true })
              log(`completed_${albumId}_${shortenedTitle}`, {
                albumId: this.state.album.id,
                title: this.audioInstance.title,
              })
            }

            // Log that the song has been listened to for at least 30s.
            if (Math.floor(this.audioInstance.currentTime) === 30 && !this.state.hasLoggedTrackListened30s) {
              this.setState({ ...this.state, hasLoggedTrackListened30s: true })
              log(`30s_${albumId}_${shortenedTitle}`, {
                albumId: this.state.album.id,
                title: this.audioInstance.title,
              })
            }

            this.setState({
              ...this.state,
              title: this.audioInstance.title,
              elapsedTime: this.audioInstance.currentTime,
              totalTime: albumInfo.duration,
            })
          }}
        />
      </div>
    )
  }
}
