import React from "react"
import { log } from "../util"
//import axios from 'axios'

const DownloadButton = ({ albumId, album, onRedeemed, redeemedText, text }) => {
  const [hasRedeemed, setHasRedeemed] = React.useState(false)
  const handleRedemption = (e) => {
    e.preventDefault()
    log(`redeem_${albumId ? "album_" + albumId.substr(0, 4) : "album"}`, { albumId })
    window.open(`https://us-central1-the-vigil-project.cloudfunctions.net/download?albumId=${albumId}`, "_blank")
    setHasRedeemed(true)
    return false
  }

  return (
    <button className={`button is-large is-block is-fullwidth is-link`} onClick={handleRedemption}>
      {hasRedeemed ? redeemedText || "Enjoy!" : text || "Download Your Bundle"}
    </button>
  )
}

export default DownloadButton
