import React from "react"
import PubSub from "pubsub-js"

import Loader from "./loader"
import { fetchCode, log } from "../util"
import { CODE_CHANGED, RESPONSE_ALBUM_LOADED } from "../events"

const CustomCodeInput = ({ code: queryCode }) => {
  const customCodeInputRef = React.useRef()
  const [isLoading, setIsLoading] = React.useState(false)
  const [hasError, setHasError] = React.useState(false)
  const [errorDescription, setErrorDescription] = React.useState("")

  const validateCustomCode = React.useCallback(
    async (code) => {
      if (isLoading) return

      if (!code) {
        code = customCodeInputRef.current.value.toUpperCase()
      }

      setIsLoading(true)

      try {
        const { data: album } = await fetchCode(code)
        PubSub.publish(RESPONSE_ALBUM_LOADED, album)
        log("valid_code_custom", { code: code, album: album.name, artist: album.artist })
      } catch (err) {
        if (customCodeInputRef.current) {
          customCodeInputRef.current.focus()
        }
        setIsLoading(false)
        setHasError(true)
        setErrorDescription(err.response ? err.response.data.message : err)
        log("invalid_code", { code })
      }
    },
    [isLoading]
  )

  React.useEffect(() => {
    if (queryCode) {
      PubSub.publish(CODE_CHANGED, queryCode)
      validateCustomCode(queryCode)
    }
  }, [queryCode, validateCustomCode])

  return (
    <>
      {isLoading && (
        <div className="container my-6">
          <Loader />
        </div>
      )}
      {!isLoading && (
        <div className="container input-container my-6">
          <div className="columns">
            <div className="column">
              <div className="control is-large is-expanded">
                <input
                  ref={customCodeInputRef}
                  className="input"
                  type="text"
                  name="code"
                  id="code"
                  placeholder="CODE"
                />
              </div>
            </div>
          </div>
          {hasError && errorDescription && (
            <div className="columns">
              <div className="column is-4 is-offset-4">
                <article className="message is-danger">
                  <div className="message-body">{errorDescription}</div>
                </article>
              </div>
            </div>
          )}
          <div className="py-3"></div>
          <div className="columns">
            <div className="column has-text-centered">
              <div className="control">
                <div className="button is-large is-link" onClick={() => validateCustomCode()}>
                  Redeem Your Download Code
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CustomCodeInput
