import React from "react"
import PubSub from "pubsub-js"

import Loader from "./loader"

import { CODE_CHANGED, RESPONSE_ALBUM_LOADED } from "../events"
import { log, fetchCode } from "../util"

const CodeInputs = ({ expectedCodeLength }) => {
  const inputRefs = React.useRef([])

  if (inputRefs.current.length !== expectedCodeLength) {
    inputRefs.current = Array(expectedCodeLength)
      .fill()
      .map((_, i) => inputRefs.current[i] || React.createRef())
  }

  const [isLoading, setIsLoading] = React.useState(false)
  const [hasError, setHasError] = React.useState(false)
  const [errorDescription, setErrorDescription] = React.useState("")
  const [code, setCode] = React.useState({})
  const [isInvalidCode, setIsInvalidCode] = React.useState(false)

  const validateCode = async (code) => {
    if (isLoading || expectedCodeLength !== code.length) return

    setIsLoading(true)
    setCode(Object.assign({}, code.split("")))

    try {
      const { data: album } = await fetchCode(code.toUpperCase())
      setIsLoading(false)

      // Send message to parent
      PubSub.publish(RESPONSE_ALBUM_LOADED, album)

      log("valid_code", { code, album: album.name, artist: album.artist })
    } catch (err) {
      if (inputRefs.current.length && inputRefs.current[0].current) {
        inputRefs.current[0].current.focus()
      }

      setIsLoading(false)
      setHasError(true)
      setErrorDescription(err.response ? err.response.data.message : err)
      setIsInvalidCode(true)

      setCode({})
      PubSub.publish(CODE_CHANGED, "")

      log("invalid_code", { code })
    }
  }

  const handleInputPaste = (e, inputIndex) => {
    if (inputIndex !== 0) return
    const data = e.clipboardData.getData("text/plain")
    if (data.length === expectedCodeLength && /[a-z0-9]+/i.test(data)) {
      const code = data.split("").reduce((acc, cur, index) => {
        acc[index] = cur
        return acc
      }, {})

      setCode(code)
      PubSub.publish(CODE_CHANGED, data)

      log("code_pasted", { code })
    }
  }

  const handleInputKeyUp = (e, inputIndex) => {
    const value = e.target.value
    if (e.keyCode === 16 || e.keyCode === 9) return

    // Backspace
    // Move to previous input
    if (e.keyCode === 8) {
      inputRefs.current[inputIndex].current.value = ""
      if (inputIndex - 1 >= 0) {
        const el = inputRefs.current[inputIndex - 1].current
        el.focus()
        el.select()
      }
      return
    }

    // Only allow alpha-numerical characters
    if (!/[abcdefghjkmnpqrstuvwxyz023456789]+/i.test(value)) {
      inputRefs.current[inputIndex].current.value = ""
      return
    }

    const newCodeObj = { ...code, [inputIndex]: value.toUpperCase() }
    const codeStr = Object.values(newCodeObj).reduce((p, c) => (p = p + c), "")

    setCode(newCodeObj)
    PubSub.publish(CODE_CHANGED, codeStr)

    if (Object.values(newCodeObj).length === expectedCodeLength) {
      validateCode(codeStr)
    } else {
      // Move to Next Input
      if (inputIndex + 1 < expectedCodeLength) {
        inputRefs.current[inputIndex + 1].current.focus()
      }
    }
  }

  const renderInputs = () => {
    const results = []
    for (let index = 0; index < expectedCodeLength; index++) {
      results.push(
        <div className="column" key={index}>
          <div className="field">
            <div className="control">
              <input
                type="text"
                className={`input is-large ${isInvalidCode ? "is-danger" : ""}`}
                maxLength={1}
                defaultValue={code[index]}
                ref={inputRefs.current[index]}
                onPaste={(e) => handleInputPaste(e, index)}
                onKeyUp={(e) => handleInputKeyUp(e, index)}
              />
            </div>
          </div>
        </div>
      )
    }
    return results
  }

  return (
    <>
      {isLoading && (
        <div className="container my-6">
          <Loader />
        </div>
      )}
      {!isLoading && (
        <div className="container input-container my-6">
          <div className="columns">{renderInputs()}</div>
          {hasError && errorDescription && (
            <div className="columns">
              <div className="column is-4 is-offset-4">
                <article className="message is-danger">
                  <div className="message-body">{errorDescription}</div>
                </article>
              </div>
            </div>
          )}
          <div className="py-3"></div>
          <div className="columns">
            <div className="column has-text-centered">
              <button
                className="button is-large is-link"
                onClick={() => validateCode(Object.values(code).reduce((p, c) => (p = p + c), ""))}>
                Redeem Your Download Code
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CodeInputs
