import React from "react"
import PubSub from "pubsub-js"
import { REQUEST_PLAY_TRACK } from "../events"
import TrackItem from "./trackItem"

const TrackList = ({ tracks, playingTrackIndex }) => {
  const trackRefs = React.useRef([])
  const trackListLength = Object.keys(tracks).length
  const onClick = (track) => PubSub.publish(REQUEST_PLAY_TRACK, track)

  if (trackRefs.current.length !== trackListLength) {
    trackRefs.current = Array(trackListLength)
      .fill()
      .map((_, i) => trackRefs.current[i] || React.createRef())
  }

  React.useEffect(() => {
    if (trackRefs.current[playingTrackIndex].current) {
      trackRefs.current[playingTrackIndex].current.scrollIntoView()
    }
  }, [playingTrackIndex])

  return (
    <aside className="menu">
      <ul className="menu-list track-list">
        {Object.values(tracks)
          .sort((a, b) => a.order - b.order)
          .map((track, index) => {
            return (
              <TrackItem
                index={index}
                ref={trackRefs.current[index]}
                track={track}
                active={index === playingTrackIndex}
                onClick={onClick}
              />
            )
          })}
      </ul>
    </aside>
  )
}

export default TrackList
