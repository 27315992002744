import NewBubble from "./newBubble"
import { shadeColor } from "../util"

import "./trackItem.scss"

const TrackItem = ({ ref, index, track, active, onClick }) => {
  return (
    <li
      className="TrackItem"
      key={track.path}
      ref={ref}
      style={{
        backgroundColor: track.meta?.backgroundColor || "inherit",
        "&:hover": {
          color: track.meta?.backgroundColor ? shadeColor(track.meta?.backgroundColor, 0.2) : "inherit",
        },
      }}>
      <button
        className={`is-linklike ${active ? "is-active" : ""}`}
        style={{
          color: track.meta?.textColor,
        }}
        onClick={() => onClick(track)}>
        <div>
          <div className="track-index">{index + 1}.</div>
          <div className="track-info">
            <span className="track-title">{track.friendlyName}</span>
            {track.meta?.artist && track.meta?.album && (
              <div className="meta">
                <span className="artist">{track.meta?.artist}</span>
                {" from "}
                <span className="album">{track.meta?.album}</span>
              </div>
            )}
          </div>
          {track.meta?.isNew && <NewBubble />}
        </div>
      </button>
    </li>
  )
}

export default TrackItem
