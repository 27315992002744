import React from "react"
import axios from "axios"

import { getQueryVariable, log } from "../util"

import Loader from "./loader"
import Album from "./album"

import "./player.scss"

class PublicPlayer extends React.Component {
  constructor(props) {
    super(props)

    this.containerRef = React.createRef()

    this.state = {
      albumId: props.albumId || getQueryVariable("albumId") || getQueryVariable("album-id"),
      isLoading: true,
      hasError: false,
      errorDescription: null,
    }
  }

  componentDidMount() {
    this.getAlbum()
  }

  getAlbum = async () => {
    this.setState({ ...this.state, isLoading: true })

    try {
      const url = "https://us-central1-the-vigil-project.cloudfunctions.net/getPublicAlbum"
      const { data } = await axios.get(url, { params: { albumId: this.state.albumId } })
      log(`fetch_album_${this.state.albumId.substr(0, 4)}`, { albumId: this.state.albumId })
      this.setState({ ...this.state, isLoading: false, album: data })

      if (typeof this.props.onAlbumLoad === "function") {
        this.props.onAlbumLoad(data)
      }
    } catch (err) {
      this.setState({
        ...this.state,
        isLoading: false,
        hasError: true,
        errorDescription: err.response ? err.response.data.message : err,
      })
      log("invalid_album", { error: err.response ? err.response.data.message : err })
    }
  }

  render() {
    return (
      <div className="PublicPlayer" ref={this.containerRef}>
        {this.state.isLoading && (
          <div className="container my-6">
            <Loader />
          </div>
        )}

        {this.state.hasError && this.state.errorDescription && (
          <div className="columns">
            <div className="column is-4 is-offset-4">
              <article className="message is-danger">
                <div className="message-body">{this.state.errorDescription}</div>
              </article>
            </div>
          </div>
        )}

        {/* HAVE ALBUM FROM CODE */}
        {this.state.album && <Album album={this.state.album} albumId={this.state.albumId} />}
      </div>
    )
  }
}

export default PublicPlayer
