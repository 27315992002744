import React from "react"

import CodePlayer from "./components/codePlayer"
import PublicPlayer from "./components/publicPlayer"

import { getQueryVariable, postHeightChangeMessage, shadeColor } from "./util"

class App extends React.Component {
  constructor(props) {
    super(props)

    const theme = getQueryVariable("theme") || "default"
    const backgroundColor = getQueryVariable("background") || "transparent"
    const textColor = getQueryVariable("text") || "#d9d9d9"
    const accentColor = getQueryVariable("accent") || "#524661"
    const accentTextColor = getQueryVariable("accentText") || getQueryVariable("accent-text") || "#fff"
    const fullWidth = getQueryVariable("fullWidth") === "true" || getQueryVariable("full-width") === "true" || false
    const hasBoxShadowHidden =
      getQueryVariable("hideBoxShadow") === "true" || getQueryVariable("hide-box-shadow") === "true" || false
    const fillParent = getQueryVariable("fillParent") === "true" || getQueryVariable("fill-parent") === "true" || false

    const titleFontFamily = getQueryVariable("titleFontFamily") || getQueryVariable("title-font-family") || "inherit"
    const titleFontSize = getQueryVariable("titleFontSize") || getQueryVariable("title-font-size") || "1.75rem"
    const bodyFontFamily = getQueryVariable("bodyFontFamily") || getQueryVariable("body-font-family") || "inherit"
    const bodyFontSize = getQueryVariable("bodyFontSize") || getQueryVariable("body-font-size") || "inherit"

    const trackListHeight = getQueryVariable("trackListHeight") || getQueryVariable("track-list-height") || "250px"
    const albumArtworkHeight =
      getQueryVariable("albumArtworkHeight") || getQueryVariable("album-artwork-height") || "350px"

    const hexTest = /^[0-9A-F]{3,6}$/i

    this.appRef = React.createRef()
    this.queryCode = getQueryVariable("code") || null

    this.height = 0

    if (fillParent) {
      document.body.style.height = "100vh"
    }

    // Query Variable options
    this.state = {
      theme,
      fullWidth,
      fillParent,
      hasBoxShadowHidden,
      backgroundColor: hexTest.test(backgroundColor) ? "#" + backgroundColor : backgroundColor,
      textColor: hexTest.test(textColor) ? "#" + textColor : textColor,
      accentColor: hexTest.test(accentColor) ? "#" + accentColor : accentColor,
      accentTextColor: hexTest.test(accentTextColor) ? "#" + accentTextColor : accentTextColor,
      titleFontFamily,
      titleFontSize,
      bodyFontFamily,
      bodyFontSize,
      trackListHeight: /^\d+$/.test(trackListHeight) ? `${trackListHeight}px` : trackListHeight,
      albumArtworkHeight: /^\d+$/.test(albumArtworkHeight) ? `${albumArtworkHeight}px` : albumArtworkHeight,
    }

    const accentLightColor = shadeColor(this.state.accentColor, 20)
    this.state.accentLightColor = accentLightColor
  }

  async componentDidUpdate(prevProps, prevState) {
    this.height = await postHeightChangeMessage(this.height)
  }

  updateStyleFromAlbum = (albumDoc) => {
    const { accentColor, accentTextColor, textColor } = albumDoc
    const accentLightColor = shadeColor(accentColor, 20)

    this.setState({ ...this.state, accentColor, accentLightColor, accentTextColor, textColor })
  }

  displayRoute() {
    const isCustomCode = getQueryVariable("isCustom") === "true" || getQueryVariable("is-custom") === "true"
    const isAlbum = getQueryVariable("albumId") !== undefined || getQueryVariable("album-id") !== undefined

    if (isAlbum) {
      return <PublicPlayer onAlbumLoad={this.updateStyleFromAlbum.bind(this)} />
    } else if (isCustomCode) {
      return <CodePlayer isCustomCode={isCustomCode} />
    } else {
      return <CodePlayer />
    }
  }

  render() {
    return (
      <div
        className={
          "App " +
          `${this.state.theme} ${this.state.fullWidth ? "is-full-width " : ""}` +
          `${this.state.hasBoxShadowHidden ? "has-box-shadow-hidden " : ""}` +
          `${this.state.fillParent ? "fill-parent " : ""}`
        }
        ref={this.appRef}
        style={{
          "--var-background-color": this.state.backgroundColor,
          "--var-text-color": this.state.textColor,
          "--var-accent-color": this.state.accentColor,
          "--var-accent-light-color": this.state.accentLightColor,
          "--var-accent-text-color": this.state.accentTextColor,
          "--var-title-font-family": this.state.titleFontFamily,
          "--var-title-font-size": this.state.titleFontSize,
          "--var-body-font-family": this.state.bodyFontFamily,
          "--var-body-font-size": this.state.bodyFontSize,
          "--var-track-list-height": this.state.trackListHeight,
          "--var-album-artwork-height": this.state.albumArtworkHeight,
        }}>
        {this.displayRoute()}
      </div>
    )
  }
}

export default App
