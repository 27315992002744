import React from "react"
import ReactDOM from "react-dom"
import App from "./App"

import firebase from "firebase/app"
import "firebase/analytics"

import posthog from "posthog-js"

import "./styles/master.scss"

posthog.init("phc_JDy6AHSYQ2UEiOG2Coy5JuzxePmVa59puKoURpaklrj", { api_host: "https://hog.vigilproject.app" })

var firebaseConfig = {
  apiKey: "AIzaSyAyAW9gVgGGICtd16AZfzJBJs0jQ9-V4fA",
  authDomain: "the-vigil-project.firebaseapp.com",
  databaseURL: "https://the-vigil-project.firebaseio.com",
  projectId: "the-vigil-project",
  storageBucket: "the-vigil-project.appspot.com",
  messagingSenderId: "396517643256",
  appId: "1:396517643256:web:972fec71509082a5b53c0d",
}

firebase.initializeApp(firebaseConfig)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)
