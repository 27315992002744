import React from "react"
import { log } from "../util"
//import axios from 'axios'

const RedeemButton = ({ code, album, onRedeemed, redeemedText, text }) => {
  const [hasRedeemed, setHasRedeemed] = React.useState(false)
  const handleRedemption = (e) => {
    e.preventDefault()
    log("redeem_code", { code })
    window.open(`https://us-central1-the-vigil-project.cloudfunctions.net/redeemCode?code=${code}`, "_blank")
    setHasRedeemed(true)
    return false
  }

  return (
    <button className={`button is-large is-block is-fullwidth is-link`} onClick={handleRedemption}>
      {hasRedeemed ? redeemedText || "Enjoy!" : text || "Download Your Bundle"}
    </button>
  )
}

export default RedeemButton
